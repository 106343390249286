import React, { useEffect, useMemo, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import add from 'assets/images/avatar/new.png'
import play from 'assets/images/avatar/play-grey.png'
import stop from 'assets/images/avatar/stop-grey.png'
import { ReactComponent as CandidatesIcon } from 'assets/images/icons/candidates-icon.svg'
import { ReactComponent as PauseIcon } from 'assets/images/icons/pause-icon.svg'
import { ReactComponent as PlayIcon } from 'assets/images/icons/play-icon.svg'
import { ReactComponent as SettingIcon } from 'assets/images/icons/settings-icon.svg'
import { ReactComponent as SmallArrowIcon } from 'assets/images/icons/small-arrow-icon.svg'
import { Button } from 'components/Button/Button'
import { JobCardRefs } from 'components/JobCard/JobCard'
import { TagList } from 'components/TagList/TagList'

import './JobList.scss'
import { RecruitersList } from 'components/RecruitersList/RecruitersList'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'

type Props = {
  status: 'add-new-job' | 'active' | 'disabled'
  tags?: Array<string>
  team?: Array<{
    firstname: string
    lastname: string
    type: 'managers' | 'recruiter'
  }>
  hiddenTeam: boolean
  hiddenEdit: boolean
  hiddenActiveStatus: boolean
  title?: string
  workspace?: WorkspaceSchema | WorkspaceSchema[]
  location?: string
  onShare?: () => void
  onEdit?: () => void
  onCreateNewVacancy?: () => void
  onListCandidates?: () => void
  onMove?: () => void
  onDuplicate?: () => void
  getRefs?: (value: JobCardRefs) => void
  toggleActive?: () => void
}

export const JobList: React.FC<Props> = ({
  status,
  tags,
  onShare,
  onEdit,
  onListCandidates,
  onMove,
  onDuplicate,
  location,
  title,
  workspace,
  team,
  hiddenTeam,
  hiddenEdit,
  hiddenActiveStatus,
  getRefs,
  toggleActive
}) => {
  const { dontShowOnGeralWorkspace, showOnGeralWorkspace } =
    useWorkspacePermission()
  const [image, setImage] = useState('')
  const addButtonRef = useRef<HTMLDivElement>(null)
  const statusRef = useRef<HTMLDivElement>(null)
  const teamRef = useRef<HTMLDivElement>(null)
  const candidatesRef = useRef<HTMLDivElement>(null)
  const shareRef = useRef<HTMLDivElement>(null)
  const editRef = useRef<HTMLDivElement>(null)

  const mountedTeam = useMemo(() => {
    return team?.reduce(
      (prev, member) => ({
        ...prev,
        [member.type]: [
          ...prev[member.type],
          `${member.firstname} ${member.lastname}`
        ]
      }),
      { recruiter: [], managers: [] }
    )
  }, [team])

  useEffect(() => {
    setImage(status === 'active' ? play : status === 'disabled' ? stop : add)

    let obj: JobCardRefs = {
      status: statusRef,
      team: teamRef,
      candidates: candidatesRef,
      share: shareRef,
      edit: editRef
    }

    if (status === 'add-new-job') {
      obj = {
        addButton: addButtonRef
      }
    }

    if (getRefs !== undefined) {
      getRefs(obj)
    }
  }, [])

  const { t } = useTranslation('jobCard')

  return (
    <>
      {status !== 'add-new-job' && (
        <div
          className='job-list-wrapper'
          style={{ padding: '15px 15px 15px 0' }}
        >
          <div className='col-3 d-flex align-items-center justify-content-left'>
            <div>
              <p className='m-0 title'>{title}</p>
              {showOnGeralWorkspace && (
                <p className='m-0 mt-1 workspace'>{workspace?.[0].name}</p>
              )}
              <p className='m-0 mt-2 location'>{location}</p>
            </div>
          </div>
          <div className='d-flex justify-content-center' style={{ flex: 1 }}>
            <RecruitersList team={mountedTeam} />
          </div>
          <div
            className='mt-3 mb-3 d-flex justify-content-center'
            style={{ flex: 1 }}
          >
            <TagList tags={tags} onClick={() => null} />
          </div>

          <div
            className='d-flex align-items-right'
            style={{ flex: 1, justifyContent: 'space-around' }}
          >
            <div
              className='m-0 d-flex user-stats'
              style={{ gap: '20px', flexWrap: 'nowrap' }}
            >
              {dontShowOnGeralWorkspace && (
                <div className='btn-job-action user-stat' ref={statusRef}>
                  <Button className='custom-color' onClick={toggleActive}>
                    {status === 'active' ? <PauseIcon /> : <PlayIcon />}
                  </Button>
                  <p>{status === 'active' ? t('stopJob') : t('startJob')}</p>
                </div>
              )}
              {dontShowOnGeralWorkspace && (
                <div className='btn-job-action user-stat' ref={editRef}>
                  <Button className='custom-color' onClick={onEdit}>
                    <SettingIcon />
                  </Button>
                  <p>{t('edit')}</p>
                </div>
              )}

              <div className='btn-job-action user-stat' ref={candidatesRef}>
                <Button className='custom-color' onClick={onListCandidates}>
                  <CandidatesIcon />
                </Button>
                <p>{t('candidates')}</p>
              </div>

              {dontShowOnGeralWorkspace && (
                <div className='btn-job-action user-stat' ref={editRef}>
                  <Button className='custom-color' onClick={onMove}>
                    <SmallArrowIcon style={{ marginLeft: '5px' }} />
                  </Button>
                  <p>{t('move')}</p>
                </div>
              )}

              {/* <div className='btn-job-action user-stat' ref={editRef}>
              <Button className='custom-color' onClick={onDuplicate}>
                <CopyIcon />
              </Button>
              <p>{t('duplicate')}</p>
            </div> */}

              {/* <div className='btn-job-action user-stat' ref={shareRef}>
              <Button className='custom-color' onClick={onShare}>
                <ShareIcon />
              </Button>
              <p>{t('share')}</p>
            </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
